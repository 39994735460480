import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ItemGuard} from '../../core/guards/item.guard';
import {ModifierGuard} from '../../core/guards/modifier.guard';



/**
 *  this route config is used for subitem and modifier,
 *  Route guard should check: item, store, category?
 *
 * */
@NgModule({
    declarations: [],
    imports: [
        CommonModule, IonicModule,
        RouterModule.forChild([
            {
                path: 'pop-up-set-menu',
                canActivate: [ItemGuard],
                loadChildren: './pop-up-set-menu/pop-up-set-menu.module#PopUpSetMenuModule'
            }, {
                path: 'set-sub-items',
                canActivate: [ItemGuard],
                loadChildren: './set-sub-items/set-sub-items.module#SetSubItemsModule'
            },
             {
                path: 'modifier-item-select',
                canActivate: [ModifierGuard],
                loadChildren: './modifier-item-select/modifier-item-select.module#ModifierItemSelectModule'
            },
            {
                path: 'pop-up-set-Img-menu',
                canActivate: [ModifierGuard],
                loadChildren: './pop-up-set-menu-withImg/pop-up-set-menu-withImg-page.module#PopUpSetMenuWithImgPageModule'
            },
            {
                path: 'multi-step-combo',
                canActivate: [ItemGuard],
                loadChildren: './multi-step-combo/multi-step-combo.module#MultiStepComboModule'
            }

        ])
    ]
})
export class ItemRouteModule {
}
