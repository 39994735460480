import {Injectable} from '@angular/core';
import {SdkBaseService} from './sdk-base-service';


@Injectable({providedIn: 'root'})
export class SdkAlipayHKService extends SdkBaseService {

    protected getServiceName() {
        return 'AlipayHKService';
    }

    public requestPay(params: any, callback) {

        if (!this.isAvailable()) { return; }

        this.callNative('requestPay', params, callback);

    }

}
