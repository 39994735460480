import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild([
            // {
            //     /**
            //      *  should add child module in app.module.ts to make it work
            //      * */
            //     path: 'category-list',
            //     // component:CategoryListPage
            //     loadChildren: '../pages/category-list/category-list.module#CategoryListModule',
            //     // canActivate: [StoreGuard],
            //     // }, {
            //     //     /**
            //     //      *  should add child module in app.module.ts to make it work
            //     //      * */
            //     //     path: 'side-category-list',
            //     //     // component:CategoryListPage
            //     //     loadChildren: '../pages/side-menu-category-list/side-menu-category-list.module#SideMenuCategoryListModule',
            //     //     // canActivate: [StoreGuard],
            // },
            {
                path: 'side-category-list',
                  loadChildren: '../pages/side-menu-category-list/side-menu-category-list-page.module#SideMenuCategoryListPageModule',
                //     // canActivate: [StoreGuard],
            },
            // {
            //     /**
            //      *
            //      * */
            //     path: 'category-item-list',
            //     // component:CategoryListPage
            //     loadChildren: '../pages/category-item-list/category-item-list.module#CategoryItemListModule',
            //     // canActivate: [StoreGuard],
            // }, {
            //     /**
            //      *
            //      * */
            //     path: 'item-grid',
            //     // component:CategoryListPage
            //     loadChildren: '../pages/item-grid/item-grid.module#ItemGridModule',
            //     // canActivate: [StoreGuard],
            // },
            // {
            //     path: 'search-item',
            //     loadChildren: '../pages/search-item/search-item.module#SearchItemModule',
            // },
            // {
            //     path: 'cart',
            //     loadChildren: './cart/cart.module#CartModule',
            // },
             {
                /**
                 *
                 * */
                path: 'multi-order-cart',
                // component:CategoryListPage
                loadChildren: './cart/multi-order-cart/multi-order-cart.module#MultiOrderCartModule',
                // canActivate: [StoreGuard],
            }, {
                path: 'qr-code',
                // component:QrCodePage
                loadChildren: '../pages/qr-code/qr-code.module#QrCodeModule',
            },
            // {
            //     path: 'order-status-prekiosk',
            //     // component:QrCodePage
            //     loadChildren: './summary/order-status-prekiosk/order-status-prekiosk.module#OrderStatusPrekioskModule',
            // }

        ])
    ]
})
export class OrderRouteModule {
}
